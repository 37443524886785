@import url('https://fonts.googleapis.com/css2?family=Mrs+Saint+Delafield&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montagu+Slab:opsz,wght@16..144,100..700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
.custom-font {
  font-family: "Marcellus", serif;
   
  }
 


  @font-face {
    font-family: "Marcellus";
    src: url("../../../public/fonts/Marcellus-Regular.ttf");
  }
  @font-face {
    font-family: "BritannySignature";
    src: url("../../../public//fonts//BrittanySignature.ttf");
  }

  .fade-in {
    opacity: 0; /* Initially set opacity to 0 */
    transition: opacity 1s ease; /* Transition property for opacity change */
  }
  .show {
    opacity: 1; /* Set opacity to 1 when the show class is applied */
  }
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  .background-image-container {
    background-image: url('../images/2-villa-interior.jpg');
  }
  .background-image-container-contact {
    background-image: url('../contactus/ContactHome.jpg');
  }
  .logo-home{
    display: flex;
    justify-content: center;
    align-items: center !important;
    width: 100%;
    padding-top: 5%;
    height: 15%;
  }
  
  .image-source{
    max-width: 20%;
  }
  .nav-tabs{
  display: flex;
  flex-direction: row;
  padding-top:5%;
  width: 100%;
  }
  
  .nav-items{
    color: rgb(238, 236, 236);
    margin-left: 12%;
  }

  
  .text-wrapup{
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
  }
  
  .englance-text{
    font-size: 60px;
  }
  
  .knowmore-botton{
    border: 2px solid white;
    margin-top: 3%;
    padding: 15px 25px;
    border-radius: 25px;
    font-size: 20px;
  }
  
  .component1-body{
    height: 100vh;
  }
  .slideshow-container {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  
  .slideshow-image {
    width: 100%;
    height: auto;
    transition: opacity 0.5s ease-in-out;
  }
  .pointer{
    position :absolute;
    top: 5400px;
    right: 505px;

  }
  /* .pointer{
    position :absolute;
    top: 5390px;
    right: 465px;

  } */
.footer-section{
  text-align: center;
}

  .zoom-in-out-box {
    
    animation: zoom-in-zoom-out 2s ease-in-out ;
  }
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.95, 0.95);
    }
    
  }
  .fade-in-text { animation: fadeIn 2s; }
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  .fade-out-text { animation: fadeIn 2s; }
  @keyframes fadeIn {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  .fade-in-img { animation: fadeIn 2s infinite; }
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
 
.video4{

  max-height: 700px !important;
  max-width: 800px !important;
  object-fit: cover !important;
}

@media screen and (width: 768px) {
  .timeless{
    margin-top: 5% !important;
  }
 }

 /* Extra small devices (phones, less than 576px) */

 /* ***************************************** */

/* Styles for devices with width 344px */
@media (max-width: 344px) {
  .mobView{
    text-align: justify;
    font-size: 15px;
   }
   .mobView-header{
    font-size: 22px !important;
   }
    #navbar{
      display: none;
    }
    .swiper-button-prev{
      display: none;
    }
    .swiper-button-next{
      display: none;
    }
   .mySwiperOne .swiper-slide{
      width:239px !important;
      margin-left: -22px !important;
      margin-right: 50px !important;
      /* height: 400px !important; */
    }
    .mySwiperSecond .swiper-slide{
      width:180px !important;
      margin-left: -37px !important;
      margin-right: 14px !important;
      /* height: 400px !important; */
    }
    .swiper {
      width: 100%;
      height:100% !important;
      margin: 5px auto;
      position: relative !important;
    }
.swiperDiv 
    .swiper-button-prev, .swiper-button-next {
      position: absolute;
       top: var(--swiper-navigation-top-offset, 20%) !important;
      /* width: calc(var(--swiper-navigation-size) /0.2) !important; */
       height: 30px !important;
       width: 100px;
      margin-top: calc(0px -(var(--swiper-navigation-size) / 1));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000 !important;
      display: none;
  }

  .swiper-button-prev::after, .swiper-button-next::after{
    display: none;
  }

  .swiper-pagination {
    display: none;
}
}

/* Styles for devices with width 360px */
@media (min-width:345px) and (max-width: 360px) {
  .mobView{
    text-align: justify;
    font-size: 15px;
   }
   .mobView-header{
    font-size: 22px !important;
   }
    #navbar{
      display: none;
    }
    .swiper-button-prev{
      display: none;
    }
    .swiper-button-next{
      display: none;
    }
   .mySwiperOne .swiper-slide{
      width:242px !important;
      margin-left: -17px !important;
      margin-right: 50px !important;
      /* height: 400px !important; */
    }
    .mySwiperSecond .swiper-slide{
      width:180px !important;
      margin-left: -32px !important;
      margin-right: 17px !important;
      /* height: 400px !important; */
    }
    .swiper {
      width: 100%;
      height:100% !important;
      margin: 5px auto;
      position: relative !important;
    }
.swiperDiv 
    .swiper-button-prev, .swiper-button-next {
      position: absolute;
       top: var(--swiper-navigation-top-offset, 20%) !important;
      /* width: calc(var(--swiper-navigation-size) /0.2) !important; */
       height: 30px !important;
       width: 100px;
      margin-top: calc(0px -(var(--swiper-navigation-size) / 1));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000 !important;
      display: none;
  }

  .swiper-button-prev::after, .swiper-button-next::after{
    display: none;
  }

  .swiper-pagination {
    display: none;
}
}

/* Styles for devices with width 375px */
@media (min-width:361px) and (max-width: 375px) {
  .mobView{
    text-align: justify;
    hyphens: auto;
    font-size: 15px;
   }
   .mobView-header{
    font-size: 22px !important;
   }
    #navbar{
      display: none;
    }
    .swiper-button-prev{
      display: none;
    }
    .swiper-button-next{
      display: none;
    }
   .mySwiperOne .swiper-slide{
      width:255px !important;
      margin-left: -20px !important;
      margin-right: 50px !important;
      /* height: 400px !important; */
    }
    .mySwiperSecond .swiper-slide{
      width:183px !important;
      margin-left: -34px !important;
      margin-right: 22px !important;
      /* height: 400px !important; */
    }
    .swiper {
      width: 100%;
      height:100% !important;
      margin: 5px auto;
      position: relative !important;
    }
.swiperDiv 
    .swiper-button-prev, .swiper-button-next {
      position: absolute;
       top: var(--swiper-navigation-top-offset, 20%) !important;
      /* width: calc(var(--swiper-navigation-size) /0.2) !important; */
       height: 30px !important;
       width: 100px;
      margin-top: calc(0px -(var(--swiper-navigation-size) / 1));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000 !important;
      display: none;
  }

  .swiper-button-prev::after, .swiper-button-next::after{
    display: none;
  }

  .swiper-pagination {
    display: none;
}
}

/* Styles for devices with width 390px */
@media (min-width:376px) and (max-width: 390px) {
  .mobView{
    text-align: justify;
    font-size: 15px;
   }
   .mobView-header{
    font-size: 22px !important;
   }
    #navbar{
      display: none;
    }
    .swiper-button-prev{
      display: none;
    }
    .swiper-button-next{
      display: none;
    }
   .mySwiperOne .swiper-slide{
      width:255px !important;
      margin-left: -8px !important;
      margin-right: 47px !important;
      /* height: 400px !important; */
    }
    .mySwiperSecond .swiper-slide{
      width:183px !important;
      margin-left: -29px !important;
      margin-right: 22px !important;
      /* height: 400px !important; */
    }
    .swiper {
      width: 100%;
      height:100% !important;
      margin: 5px auto;
      position: relative !important;
    }
.swiperDiv 
    .swiper-button-prev, .swiper-button-next {
      position: absolute;
       top: var(--swiper-navigation-top-offset, 20%) !important;
      /* width: calc(var(--swiper-navigation-size) /0.2) !important; */
       height: 30px !important;
       width: 100px;
      margin-top: calc(0px -(var(--swiper-navigation-size) / 1));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000 !important;
      display: none;
  }

  .swiper-button-prev::after, .swiper-button-next::after{
    display: none;
  }

  .swiper-pagination {
    display: none;
}
}

/* Styles for devices with width 412px */
@media (min-width:391px) and (max-width: 414px) {
  .mobView{
    text-align: justify;
    font-size: 15px;
   }
   .mobView-header{
    font-size: 22px !important;
   }
    #navbar{
      display: none;
    }
    .swiper-button-prev{
      display: none;
    }
    .swiper-button-next{
      display: none;
    }
   .mySwiperOne .swiper-slide{
      width:266px !important;
      margin-left: -2px !important;
      margin-right: 44px !important;
      /* height: 400px !important; */
    }
    .mySwiperSecond .swiper-slide{
      width:184px !important;
      margin-left: -25px !important;
      margin-right: 27px !important;
      /* height: 400px !important; */
    }
    .swiper {
      width: 100%;
      height:100% !important;
      margin: 5px auto;
      position: relative !important;
    }
.swiperDiv 
    .swiper-button-prev, .swiper-button-next {
      position: absolute;
       top: var(--swiper-navigation-top-offset, 20%) !important;
      /* width: calc(var(--swiper-navigation-size) /0.2) !important; */
       height: 30px !important;
       width: 100px;
      margin-top: calc(0px -(var(--swiper-navigation-size) / 1));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000 !important;
      display: none;
  }

  .swiper-button-prev::after, .swiper-button-next::after{
    display: none;
  }

  .swiper-pagination {
    display: none;
}
}

/* Styles for devices with width 430px */
@media (min-width:415px) and (max-width: 430px) {
  .mobView{
    text-align: justify;
    font-size: 15px;
   }
   .mobView-header{
    font-size: 22px !important;
   }
    #navbar{
      display: none;
    }
    .swiper-button-prev{
      display: none;
    }
    .swiper-button-next{
      display: none;
    }
   .mySwiperOne .swiper-slide{
      width:275px !important;
      margin-left: 1px !important;
      margin-right: 43px !important;
      /* height: 400px !important; */
    }
    .mySwiperSecond .swiper-slide{
      width:184px !important;
      margin-left: -21px !important;
      margin-right: 29px !important;
      /* height: 400px !important; */
    }
    .swiper {
      width: 100%;
      height:100% !important;
      margin: 5px auto;
      position: relative !important;
    }
.swiperDiv 
    .swiper-button-prev, .swiper-button-next {
      position: absolute;
       top: var(--swiper-navigation-top-offset, 20%) !important;
      /* width: calc(var(--swiper-navigation-size) /0.2) !important; */
       height: 30px !important;
       width: 100px;
      margin-top: calc(0px -(var(--swiper-navigation-size) / 1));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000 !important;
      display: none;
  }

  .swiper-button-prev::after, .swiper-button-next::after{
    display: none;
  }

  .swiper-pagination {
    display: none;
}
}

/* Styles for devices with width 540px */
@media (min-width:431px) and (max-width: 540px) {
  .mobView{
    text-align: justify;
    font-size: 15px;
   }
   .mobView-header{
    font-size: 22px !important;
   }
    #navbar{
      display: none;
    }
    .swiper-button-prev{
      display: none;
    }
    .swiper-button-next{
      display: none;
    }
   .mySwiperOne .swiper-slide{
      width:300px !important;
      margin-left: 25px !important;
      margin-right: 65px !important;
      /* height: 400px !important; */
    }
    .mySwiperSecond .swiper-slide{
      width:188px !important;
      margin-left: -3px !important;
      margin-right: 51px !important;
      /* height: 400px !important; */
    }
    .swiper {
      width: 100%;
      height:100% !important;
      margin: 5px auto;
      position: relative !important;
    }
.swiperDiv 
    .swiper-button-prev, .swiper-button-next {
      position: absolute;
       top: var(--swiper-navigation-top-offset, 20%) !important;
      /* width: calc(var(--swiper-navigation-size) /0.2) !important; */
       height: 30px !important;
       width: 100px;
      margin-top: calc(0px -(var(--swiper-navigation-size) / 1));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000 !important;
      display: none;
  }

  .swiper-button-prev::after, .swiper-button-next::after{
    display: none;
  }

  .swiper-pagination {
    display: none;
}
}

 /* ***************************************** */

@media (min-width: 576px) and (max-width: 767.98pxpx){
  .mobView{
    text-align: justify;
   }
    #navbar{
      display: none;
    }
   .mySwiperOne .swiper-slide{
      width:255px !important;
      margin-left: -29px !important;
      margin-right: 50px !important;
      /* height: 400px !important; */
    }
    .mySwiperSecond .swiper-slide{
      width:200px !important;
      margin-left: -45px !important;
      /* height: 400px !important; */
    }
    .swiper {
      width: 100%;
      height:100% !important;
      margin: 5px auto;
      position: relative !important;
    }
.swiperDiv 
    .swiper-button-prev, .swiper-button-next {
      position: absolute;
       top: var(--swiper-navigation-top-offset, 20%) !important;
      /* width: calc(var(--swiper-navigation-size) /0.2) !important; */
       height: 30px !important;
       width: 100px;
      margin-top: calc(0px -(var(--swiper-navigation-size) / 1));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000 !important;
      display: none;
  }

  .swiper-button-prev::after, .swiper-button-next::after{
    display: none;
  }

  .swiper-pagination {
    display: none;
}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
  .video4{

    max-height: 700px !important;
    max-width: 800px !important;
    object-fit: cover !important;
  }
  .footer-section{
    text-align: left;
  }
  .pointer{
    position :absolute;
    top: 410.5rem;
    right: 27.8rem;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }

 
  .mySwiperOne .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 700px !important;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mySwiperSecond .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 490px !important;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


 
 
  .swiper-button-prev, .swiper-button-next {
    
    color: #B48A3A !important;
}
.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1) !important;
  background: var(--swiper-pagination-color, #B48A3A) !important;

}
.swiper-pagination {
  position: absolute;
 top: 420px !important;
 z-index: 20 !important;
 font-size: 12px !important;
}

.mySwiperOne .swiper-button-prev, .swiper-button-next { 

margin-top: 40px !important;
}

.swiper-button-prev::after, .swiper-button-next::after{
font-size: 25px !important;
}
.swiper-button-prev{
padding-right: 5px !important;
background-color: rgba(255, 255, 255, 0.3);
width: 45px !important;
height: 50px;
border-radius: 50%;
color: #000000 !important;
}
.swiper-button-next {
padding-left: 5px !important;
background-color: rgba(255, 255, 255, 0.3);
width: 45px !important;
height: 50px;
border-radius: 50%;
color: #000000 !important;
}
.mySwiperSecond .swiper-button-next {
  padding-left: 5px !important;
  top: var(--swiper-navigation-top-offset, 37%) !important;
    background-color: rgba(255, 255, 255, 0.3);
  width: 45px !important;
  height: 50px;
    border-radius: 50%;
    color: #000000 !important;
    }
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: var(--swiper-pagination-color, #B48A3A) !important;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  transform: scale(0);
  transform-origin: left top;
}

.swiper-pagination-progressbar{
width: 20% !important;
margin-top: 60px !important;
margin-left: 40%;
}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023.98px) { 
  .video4{

    max-height: 700px !important;
    max-width: 800px !important;
    object-fit: cover !important;
  }
  .footer-section{
    text-align: left;
  }
  .pointer{
    position :absolute;
    top: 410.5rem;
    right: 27.8rem;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }

 
  .mySwiperOne .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 700px !important;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mySwiperSecond .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 490px !important;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


 
 
  .swiper-button-prev, .swiper-button-next {
    
    color: #B48A3A !important;
}
.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1) !important;
  background: var(--swiper-pagination-color, #B48A3A) !important;

}
.swiper-pagination {
  position: absolute;
 top: 420px !important;
 z-index: 20 !important;
 font-size: 12px !important;
}

.mySwiperOne .swiper-button-prev, .swiper-button-next { 

margin-top: 40px !important;
}

.swiper-button-prev::after, .swiper-button-next::after{
font-size: 25px !important;
}
.swiper-button-prev{
padding-right: 5px !important;
background-color: rgba(255, 255, 255, 0.3);
width: 45px !important;
height: 50px;
border-radius: 50%;
color: #000000 !important;
}
.swiper-button-next {
padding-left: 5px !important;
background-color: rgba(255, 255, 255, 0.3);
width: 45px !important;
height: 50px;
border-radius: 50%;
color: #000000 !important;
}
.mySwiperSecond .swiper-button-next {
  padding-left: 5px !important;
  top: var(--swiper-navigation-top-offset, 37%) !important;
    background-color: rgba(255, 255, 255, 0.3);
  width: 45px !important;
  height: 50px;
    border-radius: 50%;
    color: #000000 !important;
    }
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: var(--swiper-pagination-color, #B48A3A) !important;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  transform: scale(0);
  transform-origin: left top;
}

.swiper-pagination-progressbar{
width: 20% !important;
margin-top: 60px !important;
margin-left: 40%;
}
}


  /* @media only screen and (min-width: 768px) {  
  .swiper-pagination {
    position: absolute;
   top: 67px !important;
   z-index: 2 !important;
   font-size: 12px !important;
}} */

/* Above 1024 */
  @media (min-width: 1024px) {
    .video4{

      max-height: 700px !important;
      max-width: 800px !important;
      object-fit: cover !important;
    }
    .aboutUsVideo{
      max-width: 25rem !important;
    }
    .aboutUsMt{
      margin-top: -8rem !important;
    }
    .footer-section{
      text-align: left;
    }
    .fixed-width{
      max-width: 800px;
    }
    .fixed-width-1700{
      max-width: 1700px;
    }
    .pointer{
      position :absolute;
      top: 410.5rem;
      right: 27.8rem;
    }
    .swiper {
      width: 100%;
      height: 100%;
    }
 
   
    .mySwiperOne .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      height: 700px !important;
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .mySwiperSecond .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      height: 490px !important;
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
 

   
   
    .swiper-button-prev, .swiper-button-next {
      
      color: #B48A3A !important;
  }
  .swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1) !important;
    background: var(--swiper-pagination-color, #B48A3A) !important;
  
}
  .swiper-pagination {
    position: absolute;
   top: 420px !important;
   z-index: 20 !important;
   font-size: 12px !important;
}

.mySwiperOne .swiper-button-prev, .swiper-button-next { 

  margin-top: 40px !important;
}

.swiper-button-prev::after, .swiper-button-next::after{
  font-size: 25px !important;
}
.swiper-button-prev{
  padding-right: 5px !important;
  background-color: rgba(255, 255, 255, 0.3);
width: 45px !important;
height: 50px;
  border-radius: 50%;
  color: #000000 !important;
  }
 .swiper-button-next {
padding-left: 5px !important;
  background-color: rgba(255, 255, 255, 0.3);
width: 45px !important;
height: 50px;
  border-radius: 50%;
  color: #000000 !important;
  }
 .mySwiperSecond .swiper-button-next {
    padding-left: 5px !important;
    top: var(--swiper-navigation-top-offset, 37%) !important;
      background-color: rgba(255, 255, 255, 0.3);
    width: 45px !important;
    height: 50px;
      border-radius: 50%;
      color: #000000 !important;
      }
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: var(--swiper-pagination-color, #B48A3A) !important;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    transform: scale(0);
    transform-origin: left top;
}

.swiper-pagination-progressbar{
  width: 20% !important;
  margin-top: 60px !important;
  margin-left: 40%;
}
  }

  /* Above 1800 */
  @media (min-width: 1800px) {
    .video4{

      max-height: 700px !important;
      max-width: 109rem !important;
      object-fit: cover !important;
    }
    .aboutUsVideo{
      max-width: 25rem !important;
    }
    .aboutUsMt{
      margin-top: -10rem !important;
    }
    .footer-section{
      text-align: left;
    }
    .fixed-width{
      max-width: 800px;
    }
    .fixed-width-1700{
      max-width: 1700px;
    }
    .pointer{
      position :absolute;
      top: 410.5rem;
      right: 27.8rem;
    }
    .swiper {
      width: 100%;
      height: 100%;
      padding-top: 8rem !important;
      padding-bottom: 4rem !important;
    }
   
    .mySwiperOne .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      height: 700px !important;
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .mySwiperSecond .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      height: 490px !important;
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
 

   
   
    .swiper-button-prev, .swiper-button-next {
      
      color: #B48A3A !important;
  }
  .swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1) !important;
    background: var(--swiper-pagination-color, #B48A3A) !important;
  
}
  .swiper-pagination {
    position: absolute;
   top: 420px !important;
   z-index: 20 !important;
   font-size: 12px !important;
}

.mySwiperOne .swiper-button-prev, .swiper-button-next { 

  margin-top: 40px !important;
}

.swiper-button-prev::after, .swiper-button-next::after{
  font-size: 25px !important;
}
.swiper-button-prev{
  padding-right: 5px !important;
  background-color: rgba(255, 255, 255, 0.3);
width: 45px !important;
height: 50px;
  border-radius: 50%;
  color: #000000 !important;
  }
 .swiper-button-next {
padding-left: 5px !important;
  background-color: rgba(255, 255, 255, 0.3);
width: 45px !important;
height: 50px;
  border-radius: 50%;
  color: #000000 !important;
  }
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: var(--swiper-pagination-color, #B48A3A) !important;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    transform: scale(0);
    transform-origin: left top;
}

.swiper-pagination-progressbar{
  width: 20% !important;
  margin-top: 260px !important;
  margin-left: 40%;
}
  }